import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'
import { batch } from 'react-redux'

const campaignImages = async (id: string): Promise<any> => instanceAxios.get(`/images/campaign/${id}`)
const singleDelete = async (id: string): Promise<boolean> => instanceAxios.delete(`/images/single/${id}`)
const batchDelete = async (ids: string[]): Promise<boolean> => instanceAxios.delete(`/images/batch`, { params: { ids } })
const index = async (params: ApiRequest.image.index) => instanceAxios.get('/images', { params })
export default {
  index,
  campaignImages,
  single: {
    delete: singleDelete
  },
  batch: {
    delete: batchDelete
  }
}
