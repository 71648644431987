import { useSelector } from 'hooks'
import { EnumAction, EnumModule } from 'models'
import React, { ReactNode, useCallback } from 'react'

export function PermissionWrapper({
  children,
  module,
  action,
  or_actions
}: {
  children: ReactNode
  module: EnumModule
  action?: EnumAction
  or_actions?: EnumAction[]
}) {
  const { user } = useSelector((x) => x.profile)

  const checkPermission = useCallback(() => {
    const has_permission = (u: EnumAction) => user?.permissions?.find((item) => item.action === u && item.module === module) != null
    if (or_actions) {
      return or_actions.find(has_permission) != null
    }
    return action != null && has_permission(action)
  }, [or_actions, action, module])

  if (!checkPermission()) {
    return <div />
  }

  return children
}
