/* eslint-disable no-return-assign */
import { IconButtonProps } from 'components'
import { ReactElement } from 'react'
import { HttpMethod } from './common'
import { TableSelectData } from './redux/table'

export interface TableConfig<ItemT> {
  url?: string
  method?: HttpMethod
  search?: {
    placeholder?: string
  }
  select?: {
    multiple?: boolean
    header_select?: 'current_page' | 'all_pages'
    btn?: {
      label?: string
    }
  }
  default_params?: any
  filters?: {
    inputs: InputConfig<any>[]
  }

  columns: TableColumn<any>[]
  row_actions?: (TableRowAction | ((a: any) => TableRowAction))[]
  row_action_first?: boolean
  code: EnumTable
  select_actions?: TableSelectedAction[]
  default_per_page?: number
}

export interface TableSelectedAction extends Omit<IconButtonProps, 'onClick'> {
  onClick?: (selectData: TableSelectData, filter_params: any) => void
  id?: string
  popup?: {
    title: string
  }
}

export interface TableRowAction extends Omit<IconButtonProps, 'onClick'> {
  onClick?: (item: any) => void
  id?: string
}
export interface TableColumn<ItemT> {
  header: {
    label: string
  }
  cell: {
    // Có 3 option render ra cell
    // key: chỉ định tên property -> item[key]
    // getValue: chỉ định function trả về giá trị label
    // getElement: chỉ định function trả về luôn 1 React Component
    key?: string
    getValue?: (a: ItemT) => string | number
    getElement?: (a: ItemT) => ReactElement
    width?: number
    align?: 'left' | 'center' | 'right'
  }
}
export type InputType = 'dropdown' | 'input_text' | 'input_number' | 'input_date' | 'dropdown_multiple' | 'input_textarea'
export interface InputConfig<ItemT> {
  label?: string
  grid_xs?: number
  name?: string
  value?: string | boolean
  placeholder?: string
  type?: InputType
  url?: string

  regex?: RegExp

  data?: ItemT[]
  getLabel?: (a: ItemT) => string
  disabled?: boolean
  require?: boolean
  hidden?: boolean
  onChange?: (a: any) => void
  error_msg?: string
}

export enum EnumTable {
  NOTICE_ARRIVAL = 'NOTICE_ARRIVAL',
  CAMPAIGN_LIST = 'CAMPAIGN',
  PRODUCT_LIST = 'PRODUCT_LIST',
  USER_PORTAL_LIST = 'USER_PORTAL',
  GROUP_PERMISSION_USER_LIST = 'GROUP_PERMISSION_USER',
  STORE_UNIT_LIST = 'STORE_UNIT_LIST',
  GROUP_PERMISSION_LIST = 'GROUP_PERMISSION_LIST',
  ORDER_LIST = 'ORDER_LIST',
  REORDER_LIST = 'REORDER_LIST',
  NEW_PRODUCT_ORDER_LIST = 'NEW_PRODUCT_ORDER_LIST'
}
