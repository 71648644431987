import { EntityBase } from 'models/entity'
import { EnumModule } from './module'

export interface PermissionEntity extends EntityBase {
  module: EnumModule
  action: EnumAction
  label?: string
}
export enum EnumAction {
  CREATE = 'create',
  MANAGE = 'manage',
  UPDATE = 'update',
  VIEW = 'view',
  DELETE = 'delete',
  SYNC_PORTAL = 'sync_portal',

  CAMPAIGN_PRODUCT_VIEW = 'campaign_product_view',
  CAMPAIGN_PRODUCT_EDIT = 'campaign_product_edit',

  CAMPAIGN_ORDER_COMPANY_VIEW = 'campaign_order_company_view',
  CAMPAIGN_ORDER_COMPANY_EDIT = 'campaign_order_company_edit',
  CAMPAIGN_ORDER_AREA_VIEW = 'campaign_order_area_view',
  CAMPAIGN_ORDER_AREA_EDIT = 'campaign_order_area_edit',
  CAMPAIGN_ORDER_STORE_VIEW = 'campaign_order_store_view',
  CAMPAIGN_ORDER_STORE_EDIT = 'campaign_order_store_edit',

  CAMPAIGN_COMMENT_VIEW = 'campaign_comment_view',
  CAMPAIGN_COMMENT_EDIT = 'campaign_comment_edit',

  CAMPAIGN_REPORT_CREATE = 'campaign_report_create',
  CAMPAIGN_PERMISSION_EDIT = 'campaign_permission_edit'
}

export const ALL_PERMISSIONS: Partial<PermissionEntity>[] = [
  // Module Chiến dịch
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_PRODUCT_VIEW,
    label: 'Xem sản phẩm'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_PRODUCT_EDIT,
    label: 'Cập nhật sản phẩm/ảnh'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_COMPANY_VIEW,
    label: 'Xem đơn Merchandise'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_COMPANY_EDIT,
    label: 'Đặt đơn Merchandise'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_AREA_VIEW,
    label: 'Xem đơn vùng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_AREA_EDIT,
    label: 'Đặt đơn vùng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_STORE_VIEW,
    label: 'Xem đơn cửa hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_ORDER_STORE_EDIT,
    label: 'Đặt đơn cửa hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_COMMENT_VIEW,
    label: 'Xem đánh giá'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_COMMENT_EDIT,
    label: 'Cập nhật đánh giá'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_REPORT_CREATE,
    label: 'Xuất báo cáo nhanh/tổng quan'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_PERMISSION_EDIT,
    label: 'Phân quyền đặt hàng'
  },
  // Module Thông báo hàng về
  {
    module: EnumModule.NOTICE_ARRIVAL,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.NOTICE_ARRIVAL,
    action: EnumAction.MANAGE,
    label: 'Quản trị'
  },
  {
    module: EnumModule.NOTICE_ARRIVAL,
    action: EnumAction.CREATE,
    label: 'Thêm mới'
  },
  {
    module: EnumModule.NOTICE_ARRIVAL,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.NOTICE_ARRIVAL,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },

  // Module Tái đặt hàng
  {
    module: EnumModule.REORDER,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.REORDER,
    action: EnumAction.MANAGE,
    label: 'Quản trị'
  },
  {
    module: EnumModule.REORDER,
    action: EnumAction.CREATE,
    label: 'Thêm mới'
  },
  {
    module: EnumModule.REORDER,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.REORDER,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  // Module Yêu cầu sản phẩm mới
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    action: EnumAction.MANAGE,
    label: 'Quản trị'
  },
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    action: EnumAction.CREATE,
    label: 'Thêm mới'
  },
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },

  // Module Phân quyền
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  // Module Thông tin Portal
  {
    module: EnumModule.PORTAL_DATA,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.PORTAL_DATA,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ từ Portal'
  }
]
