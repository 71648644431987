/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumViewMode, ReorderState } from 'models'
import { ReorderEntity } from 'models/reorder'
const initialState: ReorderState = {}
const slice = createSlice({
  name: 'REORDER_STATE',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<ReorderEntity | undefined>) => {
      state.data = payload
      state.mode = payload === undefined ? EnumViewMode.CREATE : EnumViewMode.VIEW
    },
    setMode: (state, { payload }: PayloadAction<EnumViewMode>) => {
      state.mode = payload
    }
  }
})
export const { actions: reorderAction, reducer: reorderReducer } = slice
