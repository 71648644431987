import { EntityBase } from '../entity'
import { PermissionEntity } from './permission'

export interface GroupPermissionEntity extends EntityBase {
  name: string
  role?: EnumDefaultRole
  description: string
  permissions?: PermissionEntity[]
}

export enum EnumDefaultRole {
  ADMIN = 1,
  PRODUCT_MANAGER = 2,
  MERCHANDISE = 3,

  BUSINESS_MANAGER = 4,
  STORE_MANAGER = 5,
  REVIEWER = 6
}
export const DefaultRoleLabel: Record<EnumDefaultRole, string> = {
  [EnumDefaultRole.ADMIN]: 'Quản trị viên',
  [EnumDefaultRole.PRODUCT_MANAGER]: 'Khối sản xuất',
  [EnumDefaultRole.MERCHANDISE]: 'Khối Merchandise',
  [EnumDefaultRole.BUSINESS_MANAGER]: 'Quản lý vùng',
  [EnumDefaultRole.STORE_MANAGER]: 'Quản lý cửa hàng',
  [EnumDefaultRole.REVIEWER]: 'Nhóm đánh giá'
}
