import { instanceAxios } from 'utils'

import { ApiRequest } from './interface'
import { GroupPermissionEntity } from 'models'

const index = async (): Promise<any> => instanceAxios.get('/group-permissions')

const show = async (id: string): Promise<any> => instanceAxios.get(`/group-permissions/${id}`)

const create = async (data: ApiRequest.group_permission.create) => instanceAxios.post('/group-permissions', data)

const update = async (id: string, data: ApiRequest.group_permission.update) => instanceAxios.patch(`/group-permissions/${id}`, data)

const removeUsers = async (id: string, users: string[]): Promise<GroupPermissionEntity> =>
  instanceAxios.delete(`group-permissions/${id}/users`, {
    data: { users }
  })

const addUsers = async (id: string, users: string[]): Promise<GroupPermissionEntity> => instanceAxios.post(`group-permissions/${id}/users`, { users })

export default {
  index,
  show,
  create,
  update,
  users: {
    remove: removeUsers,
    add: addUsers
  }
}
