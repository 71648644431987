/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumAction, EnumModule, ProfileStateEntity, StoreEntity, UserEntity } from 'models'
import {store} from 'store'
export function hasPermission(module: EnumModule, action: EnumAction) {
  var permissions = store.getState().profile?.user?.permissions ?? []
  return permissions.find((item) => item.module === module && item.action === action) != null
}

const initialState: ProfileStateEntity = {}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<UserEntity>) => {
      state.user = payload
    }
  }
})
export const { actions: profileActions, reducer: profileReducer } = slice
