import React, { useRef } from 'react'

import { Stack, Tooltip, Typography } from '@mui/material'
import { DialogMain, UiAvatar, UIDialogRef } from 'components'
import { env } from 'config'
import { useSelector } from 'hooks'
import { COLORS } from 'utils'
import { PermissionList } from 'pages/permission/detail/permission_list'

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export function Profile() {
  const { user } = useSelector((x) => x.profile)
  const { permissions } = user ?? {}
  const ref = useRef<UIDialogRef | null>(null)
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <UiAvatar uri={user?.avatar} style={{ width: 36, height: 36 }} />
      <Stack direction="row" alignItems="flex-start" spacing={0.5} sx={{ ml: 3 }}>
        <Stack direction="column">
          <Tooltip title="Chỉnh sửa thông tin">
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                color: '#333',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {user?.name}
            </Typography>
          </Tooltip>
          <div onClick={() => ref?.current?.show()}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                color: COLORS.CelticBlue,
                textDecorationLine: 'underline',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {'Xem quyền của tôi'}
            </Typography>
          </div>
        </Stack>
      </Stack>
      <DialogMain ref={ref} label="" onClose={() => ref?.current?.hide()}>
        <Stack>
          <PermissionList data={permissions ?? []} />
        </Stack>
      </DialogMain>
    </Stack>
  )
}
