export interface ModuleEntity {
  module: EnumModule
  label: string
}

export enum EnumModule {
  NOTICE_ARRIVAL = 'notice_arrival',
  GROUP_PERMISSION = 'permission',
  REORDER = 'reorder',
  NEW_PRODUCT_ORDER = 'new_product_order',
  CAMPAIGN = 'campaign',
  PORTAL_DATA = 'portal_data'
}
export const ALL_MODULES: ModuleEntity[] = [
  {
    module: EnumModule.CAMPAIGN,
    label: 'Triển lãm'
  },
  {
    module: EnumModule.NOTICE_ARRIVAL,
    label: 'Thông báo hàng về'
  },
  {
    module: EnumModule.REORDER,
    label: 'Tái đặt hàng'
  },
  {
    module: EnumModule.PORTAL_DATA,
    label: 'Thông tin Portal'
  },
  {
    module: EnumModule.NEW_PRODUCT_ORDER,
    label: 'Ý tưởng hàng mới'
  },
  {
    module: EnumModule.REORDER,
    label: 'Tái đặt hàng'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    label: 'Phân quyền'
  }
]
