import { PermissionEntity } from './permissions'
import { EnumModule } from './permissions/module'
export interface MenuEntity {
  href?: string
  title: string
  permissions: Partial<PermissionEntity>[]
  id: EnumModule
  only_admin?: boolean
  align?: 'left' | 'center' | 'right'
}

export const MenuUrl: Record<EnumModule, string> = {
  campaign: '/campaign',
  portal_data: '/portal_data',
  reorder: '/reorder',
  new_product_order: '/new_product_order',
  group_permission: '/group_permission',
  notice_arrival: '/notice_arrival'
}
