import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { lazy, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Loadable, ProtectedAuth } from 'components'
import { BASE_THEME } from 'constant'
import { useSelector } from 'hooks'
import { MenuUrl } from 'models'
import Api from 'services'
import { persistor, profileActions, store } from 'store'
import { CookiesCustom } from 'utils'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))

const NoticeArrivalDetail = Loadable(lazy(() => import('pages/notice_arrival/detail')))
const NoticeArrivalList = Loadable(lazy(() => import('pages/notice_arrival/list')))
const CampaignList = Loadable(lazy(() => import('pages/campaign/list')))
const CampaignEdit = Loadable(lazy(() => import('pages/campaign/edit')))

const GroupPermissionList = Loadable(lazy(() => import('pages/permission/list')))
const GroupPermissionDetail = Loadable(lazy(() => import('pages/permission/detail')))

const PortalData = Loadable(lazy(() => import('pages/portal_data')))

const ReorderList = Loadable(lazy(() => import('pages/reorder/list')))

const NewProductOrderList = Loadable(lazy(() => import('pages/new_product_order/list')))
function AppRoutes() {
  const { user } = useSelector((x) => x.profile)
  const dispatch = useDispatch()
  const has_profile = user != undefined

  useEffect(() => {
    if (has_profile) {
      return
    }
    const token = CookiesCustom.getToken()
    if (!token) {
      return
    }
    Api.me
      .show()
      .then((res: any) => {
        dispatch(profileActions.setProfile(res?.data?.data))
      })
      .catch((e) => {})
  }, [has_profile])

  if (!has_profile) {
    return <div />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/404" element={<PageNotFound />} />
        <Route
          path="/"
          element={
            <ProtectedAuth>
              <MainLayout />
            </ProtectedAuth>
          }
        >
          <Route path={MenuUrl.campaign} element={<CampaignList />} />
          <Route path={`${MenuUrl.campaign}/detail/:id`} element={<CampaignEdit />} />
          <Route path={`${MenuUrl.campaign}/create`} element={<CampaignEdit />} />

          <Route path={MenuUrl.notice_arrival} element={<NoticeArrivalList />} />
          <Route path={`${MenuUrl.notice_arrival}/detail/:id`} element={<NoticeArrivalDetail />} />
          <Route path={`${MenuUrl.notice_arrival}/create`} element={<NoticeArrivalDetail />} />

          <Route path={MenuUrl.reorder} element={<ReorderList />} />

          <Route path={MenuUrl.new_product_order} element={<NewProductOrderList />} />

          <Route path={MenuUrl.portal_data} element={<PortalData />} />

          <Route path={MenuUrl.group_permission} element={<GroupPermissionList />} />
          <Route path={`${MenuUrl.group_permission}/:id`} element={<GroupPermissionDetail />} />
        </Route>

        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
