import { CampaignEntity, CampaignStatusLabel, CampaignTypeLabel, NoticeArrival, TableColumn } from 'models'
import { EnumNoticeTagType, NoticeTag } from 'models/notice_arrival/tag'
import { formatDateEn } from 'utils'

const getTagName = (tags: NoticeTag[] | undefined, type: EnumNoticeTagType) => {
  return tags?.find((item) => item.type === type)?.name ?? ''
}
const Table: TableColumn<NoticeArrival>[] = [
  {
    header: {
      label: 'ID'
    },
    cell: { getValue: (a: NoticeArrival) => a.code }
  },
  {
    header: {
      label: 'DPO/IPO'
    },
    cell: { getValue: (a: NoticeArrival) => a.dpo_code }
  },
  {
    header: {
      label: 'Dòng hàng'
    },
    cell: { getValue: (a: NoticeArrival) => a.categories }
  },
  {
    header: {
      label: 'Số lượng'
    },
    cell: { getValue: (a: NoticeArrival) => a.quantity }
  },
  {
    header: {
      label: 'Ngày về dự kiến'
    },
    cell: { getValue: (a: NoticeArrival) => formatDateEn(a.arrival_at) }
  },
  {
    header: {
      label: 'Là template'
    },
    cell: { getValue: (a: NoticeArrival) => (a.is_template == true ? '✅' : '') }
  },
  {
    header: {
      label: 'Thương hiệu'
    },
    cell: { getValue: (a: NoticeArrival) => getTagName(a.tags, EnumNoticeTagType.BRAND), width: 100 }
  },
  {
    header: {
      label: 'Nguồn hàng'
    },
    cell: { getValue: (a: NoticeArrival) => getTagName(a.tags, EnumNoticeTagType.GOOD_SOURCE), width: 100 }
  },
  {
    header: {
      label: 'Loại hàng'
    },
    cell: {
      getValue: (a: NoticeArrival) => getTagName(a.tags, EnumNoticeTagType.GOOD_CATEGORY)
    }
  },
  {
    header: {
      label: 'Nhóm hàng'
    },
    cell: {
      getValue: (a: NoticeArrival) => getTagName(a.tags, EnumNoticeTagType.GOOD_GROUP)
    }
  },
  {
    header: {
      label: 'Người tạo'
    },
    cell: { getValue: (a: NoticeArrival) => a.created_by?.name ?? '' }
  },
  {
    header: {
      label: 'Thời gian tạo'
    },
    cell: { getValue: (a: NoticeArrival) => formatDateEn(a.created_at) }
  }
]
export default Table
