/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumViewMode, NewProductOrder, NewProductOrderState } from 'models'
const initialState: NewProductOrderState = {}
const slice = createSlice({
  name: 'NEW_PRODUCT_ORDER_STATE',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<NewProductOrder | undefined>) => {
      state.data = payload
      state.mode = payload === undefined ? EnumViewMode.CREATE : EnumViewMode.VIEW
    },
    setMode: (state, { payload }: PayloadAction<EnumViewMode>) => {
      state.mode = payload
    }
  }
})
export const { actions: newProductOrderAction, reducer: newProductOrderReducer } = slice
