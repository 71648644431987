import { AreaEntity } from './area'
import { StoreEntity } from './store'
import { UserEntity } from './user_portal'

export interface OrgUnitEntity {
  children: OrgUnitEntity[]
  code: string
  parent?: OrgUnitEntity
  leader: UserEntity
  sub_leaders?: UserEntity[]
  level: number
  name: string
  type: string
  mapping_store?: StoreEntity
  mapping_area?: AreaEntity
  users: UserEntity[]
  slug: string
  id: string
}

export enum EnumOrgUnitType {
  DIVISION = 'division',
  AREA = 'area',
  BUSINESS_UNIT = 'business_unit',
  STORE = 'store',
  DEPARTMENT = 'department',
  TEAM = 'team'
}
