/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CampaignEntity, CampaignStateEntity, CategoryEntity } from 'models'
const initialState: CampaignStateEntity = {}
const slice = createSlice({
  name: 'CAMPAIGN_STATE',
  initialState,
  reducers: {
    setCampaign: (state, { payload }: PayloadAction<CampaignEntity>) => {
      state.campaign = { ...payload }
    },
    updateCampaign: (state, { payload }: PayloadAction<Partial<CampaignEntity>>) => {
      if (!state.campaign) {
        return
      }
      if (state.campaign) {
        state.campaign = {
          ...state.campaign,
          ...payload
        }
      }
    },
    setCategories: (state, { payload }: PayloadAction<CategoryEntity[]>) => {
      state.categories = payload
    }
  }
})
export const { actions: campaignActions, reducer: campaignReducer } = slice
