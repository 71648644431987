import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const markTemplate = (id: string, is_template: boolean) => instanceAxios.patch(`/notice-arrivals/${id}/mark-template`, { is_template })
const cloneTemplate = (id: string) => instanceAxios.get(`/notice-arrivals/${id}/clone`)
const update = (id: string, data: ApiRequest.arrival_notice.create) => instanceAxios.patch(`/notice-arrivals/${id}`, data)
const create = (data: ApiRequest.arrival_notice.create) => instanceAxios.post('/notice-arrivals', data)
const del = (id: string) => instanceAxios.delete(`/notice-arrivals/${id}`)
const show = (id: string) => instanceAxios.get(`/notice-arrivals/${id}`)
const tagList = () => instanceAxios.get('/notice-arrivals/tags')
const addFile = (id: string, file: File) => {
  const form = new FormData()
  form.append('file', file)
  return instanceAxios.post(`/notice-arrivals/${id}/add-file`, form, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}
const userFind = (emails: string[]) =>
  instanceAxios.get('/notice-arrivals/users', {
    params: {
      emails
    }
  })
export default {
  tags: { index: tagList },
  users: { find: userFind },
  create,
  update,
  show,
  delete: del,
  addFile,
  template: {
    mark: markTemplate,
    clone: cloneTemplate
  }
}
